<app-custom-ion-header #header [selected]="'Rechnungen'" [homeIcon]="true" [reloadIcon]="true"></app-custom-ion-header>
<app-mobile-ion-header #header (onReload)="reloadData(true)" [selected]="'Rechnungen'" [homeIcon]="true" [reloadIcon]="true"></app-mobile-ion-header>
<ion-content [fullscreen]="true">
  <app-custom-ion-condense-header #header [selected]="'Rechnungen'" [homeIcon]="true" [reloadIcon]="true"></app-custom-ion-condense-header>

  <div>
    <app-custom-content-wrapper [breadcrumb]="breadcrumb" [ngClass]="appMode ? 'appMode' : ''">
      <ng-template #wrapperContent>
        <app-content-header [contentTitle]="'Rechnungsverwaltung' | translate"></app-content-header>
        <div *ngIf="!appMode" class="reload" (click)="reloadData(true)">
          {{ "COMMON.RELOAD" | translate }}
        </div>
        <div class="app-header" style="align-items: center">
          <div class="search-comp">
            <app-search-box [formControl]="globalSearchField"></app-search-box>
          </div>
        </div>
        <br />
        <ng-container *ngFor="let tabGroup of tabGroups">
          <div class="tab" (click)="switchTab(tabGroup, tab)" [ngClass]="tab.active ? 'active' : ''" *ngFor="let tab of tabGroup.tabs">
            <ng-container *ngIf="!tab.needReload && tab.count > -1; else nocount"> {{ tab.title }} ({{ tab.searchCount ?? tab.count }}) </ng-container>
            <ng-template #nocount>
              {{ tab.title }}
            </ng-template>
          </div>
        </ng-container>
        <br /><br />
        <div class="main-view">
          <div class="desktop">
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear multiTemplateDataRows>
              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <button
                    class="button-table"
                    *ngIf="row.orders && row.orders.length > 0"
                    (click)="addOrRemove(expandedRows, row); updateInnerSort(); $event.stopPropagation()"
                  >
                    <span *ngIf="!expandedRows.includes(row)" class="add"></span>
                    <span *ngIf="expandedRows.includes(row)" class="minus"></span>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="invoiceType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
                <td mat-cell #highlightCell *matCellDef="let row" [ngClass]="expandedRows.includes(row) ? 'identifier' : ''">{{ row.invoiceType }}</td>
              </ng-container>

              <ng-container matColumnDef="invoiceNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Vorgang</th>
                <td mat-cell #highlightCell *matCellDef="let row" [ngClass]="expandedRows.includes(row) ? 'identifier' : ''">{{ row.invoiceNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="orderNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Auftrag</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ getOrderNumbers(row) }}</td>
              </ng-container>

              <ng-container matColumnDef="customerReference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Referenz Kunde</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ getCustomerReference(row) }}</td>
              </ng-container>

              <ng-container matColumnDef="invoiceDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Rechnungsdatum</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ getFixedDate(row.invoiceDate) }}</td>
              </ng-container>

              <ng-container matColumnDef="invoiceDueDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fällig bis</th>
                <td mat-cell #highlightCell *matCellDef="let row" [ngClass]="isPastDate(row.invoiceDueDate) ? 'past-date' : ''">
                  {{ getFixedDate(row.invoiceDueDate) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="invoiceAmount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Betrag</th>
                <td mat-cell #highlightCell *matCellDef="let row">{{ row.invoiceAmount | currency: "EUR" : "symbol" : undefined : "de-DE" }}</td>
              </ng-container>

              <ng-container matColumnDef="invoiceAmountMinusDiscount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Betrag abzgl. Skonto</th>
                <td mat-cell #highlightCell *matCellDef="let row">
                  {{ row.invoiceAmount - row.amountEarly | currency: "EUR" : "symbol" : undefined : "de-DE" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="dateEarly">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Skonto bis</th>
                <td mat-cell #highlightCell *matCellDef="let row" [ngClass]="isPastDate(row.invoiceDueDate) ? 'past-date' : ''">
                  {{ getFixedDate(row.dateEarly) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="pdf">
                <th mat-header-cell *matHeaderCellDef>PDF</th>
                <td mat-cell *matCellDef="let row">
                  <div *ngIf="row.attachmentPath" class="pdf inline" (click)="getInvoiceAttachment(row)">
                    <img src="./assets/icons/FilePdfRed.png" alt="" height="20px" />
                  </div>
                  <span *ngIf="!row.attachmentPath"> - </span>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" [hidden]="!expandedRows.includes(row)">
                  <ng-container *ngIf="expandedRows.includes(row)">
                    <ng-container *ngFor="let order of row.orders">
                      <div class="expandedContent">
                        <div>
                          <div class="description">
                            <div>
                              <button
                                class="button-table"
                                (click)="addOrRemove(detailExpandedRows, order); loadInvoiceOrderPositions(order); updateInnerSort(); $event.stopPropagation()"
                              >
                                <span *ngIf="!detailExpandedRows.includes(order)" class="add"></span>
                                <span *ngIf="detailExpandedRows.includes(order)" class="minus"></span>
                              </button>
                              <span class="order-section"> Auftrag {{ order.orderNumber }} </span>
                            </div>
                            <div class="admin-edit-container">
                              <app-admin-edit (editFired)="adminEdit(row)" *ngIf="currentTab.id !== 2"></app-admin-edit>
                            </div>
                          </div>
                          <table class="summary-table">
                            <tr>
                              <th>Brutto Waren</th>
                              <th>Kosten</th>
                              <th>Sonstige</th>
                              <th>Verrechn.</th>
                              <th>Auftragsrab.</th>
                              <th>Rabatt</th>
                              <th>Nettobetrag</th>
                              <th>USt gesamt 19%</th>
                              <th>Gesamtbetrag</th>
                            </tr>
                            <tr>
                              <td>{{ row.amountGoods }}</td>
                              <td>{{ row.amountCosts }}</td>
                              <td>{{ row.amountOther }}</td>
                              <td>{{ row.amountOffset }}</td>
                              <td>{{ row.discountPercentage }}</td>
                              <td>{{ row.discountAmount }}</td>
                              <td>{{ row.amountNett }}</td>
                              <td>{{ row.amountTax }}</td>
                              <td>{{ row.amountGross }}</td>
                            </tr>
                          </table>
                        </div>
                        <div class="inner-table" *ngIf="detailExpandedRows.includes(order)">
                          <table
                            #innerTables
                            mat-table
                            #innerSort="matSort"
                            [dataSource]="order.positionsDataSource"
                            matSort
                            matSortDisableClear
                            multiTemplateDataRows
                          >
                            <ng-container matColumnDef="expand">
                              <th mat-header-cell *matHeaderCellDef></th>
                              <td mat-cell *matCellDef="let row">
                                <button *ngIf="row.longDescription" class="button-table" (click)="addOrRemove(descriptionExpandedRows, row)">
                                  <span *ngIf="!descriptionExpandedRows.includes(row)" class="add"></span>
                                  <span *ngIf="descriptionExpandedRows.includes(row)" class="minus"></span>
                                </button>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="thumbnailFileName">
                              <th mat-header-cell *matHeaderCellDef>Bild</th>
                              <td mat-cell *matCellDef="let row">
                                <img
                                  *ngIf="row.thumbnailFileBase64"
                                  id="image{{ row.thumbnailFileName }}"
                                  width="50"
                                  src="data:image/gif;base64,{{ row.thumbnailFileBase64 }}"
                                  alt="{{ row.thumbnailFileName }}"
                                  (click)="previewImage(row)"
                                />
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="articleNumber">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Artikelnummer</th>
                              <td mat-cell *matCellDef="let row">{{ row.articleNumber }}</td>
                            </ng-container>
                            <ng-container matColumnDef="shipmentDate">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Lieferdatum</th>
                              <td mat-cell *matCellDef="let row">{{ row.shipmentDate | date: "dd.MM.yyyy" }}</td>
                            </ng-container>
                            <ng-container matColumnDef="quantity">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Menge</th>
                              <td mat-cell *matCellDef="let row">{{ row.quantity }}</td>
                            </ng-container>
                            <ng-container matColumnDef="nettAmount">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Nettobetrag</th>
                              <td mat-cell *matCellDef="let row">{{ row.nettAmount }}</td>
                            </ng-container>
                            <ng-container matColumnDef="discount">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Rabatt</th>
                              <td mat-cell *matCellDef="let row">{{ row.discount }}</td>
                            </ng-container>
                            <ng-container matColumnDef="grossAmount">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Betrag</th>
                              <td mat-cell *matCellDef="let row">{{ row.grossAmount }}</td>
                            </ng-container>
                            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail">
                              <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length" [hidden]="!descriptionExpandedRows.includes(row)">
                                <ng-container *ngIf="descriptionExpandedRows.includes(row)">
                                  <span [innerHtml]="row.longDescription"></span>
                                </ng-container>
                              </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expandedDetail"></tr>
                          </table>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expandedDetail"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">Keine Lieferscheine gefunden</td>
              </tr>
            </table>
            <mat-paginator
              [hidden]="dataSource.filteredData.length < environment.ENTRIES_PER_PAGE"
              [pageSize]="environment.ENTRIES_PER_PAGE"
              hidePageSize
              showFirstLastButtons
            ></mat-paginator>
          </div>

          <div class="mobile">
            <app-pager #pager1 (pageChanged)="onPageChanged($event)"></app-pager>

            <mat-card class="card mat-mdc-elevation-specific mat-elevation-z2" *ngFor="let item of mobileData; let i = index" appearance="raised">
              <mat-card-header>
                <mat-card-title-group>
                  <mat-card-title class="card-title">
                    <span class="title" #highlightCell>{{ item.invoiceNumber }}</span>
                    <span class="date-mobile" *ngIf="item.createdDate !== null">({{ getFixedDate(item.createdDate) }})</span>
                  </mat-card-title>
                  <mat-card-subtitle #highlightCell class="pre-line">{{ getCustomerReference(item) }}</mat-card-subtitle>
                </mat-card-title-group>
              </mat-card-header>
              <mat-card-content>
                <dl class="grid">
                  <dt>Typ:</dt>
                  <dd #highlightCell>{{ item.invoiceType }}</dd>

                  <dt>Rechnungsdatum:</dt>
                  <dd #highlightCell>{{ getFixedDate(item.invoiceDate) }}</dd>

                  <dt>Fällig bis:</dt>
                  <dd #highlightCell [ngClass]="isPastDate(item.invoiceDueDate) ? 'past-date' : ''">{{ getFixedDate(item.invoiceDueDate) }}</dd>

                  <dt>Auftrag:</dt>
                  <dd #highlightCell class="pre-line">{{ getOrderNumbers(item) }}</dd>

                  <dt>Betrag:</dt>
                  <dd #highlightCell>{{ item?.invoiceAmount | currency: "EUR" : "symbol" : undefined : "de-DE" }}</dd>

                  <dt>Betrag abzgl. Skonto:</dt>
                  <dd #highlightCell>{{ item?.invoiceAmount - item?.amountEarly | currency: "EUR" : "symbol" : undefined : "de-DE" }}</dd>

                  <dt>Skonto bis:</dt>
                  <dd #highlightCell [ngClass]="isPastDate(item.invoiceDueDate) ? 'past-date' : ''">{{ getFixedDate(item.dateEarly) }}</dd>
                </dl>
              </mat-card-content>
              <mat-card-actions class="card-actions">
                <button *ngIf="item.attachmentPath" class="menu-button" mat-icon-button (click)="getInvoiceAttachment(item)">
                  <img src="./assets/icons/FilePdfRed.png" alt="" height="20px" />
                </button>
              </mat-card-actions>
            </mat-card>

            <div *ngIf="mobileData.length === 0">Keine Lieferscheine gefunden</div>
            <app-pager #pager2 (pageChanged)="onPageChanged($event)"></app-pager>
          </div>
        </div>
      </ng-template>
    </app-custom-content-wrapper>
  </div>
</ion-content>
<app-custom-content-footer></app-custom-content-footer>
