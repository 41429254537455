<div class="tabs">
  <div class="tab active">Benutzerverwaltung</div>
</div>
<div>
  <mat-form-field class="filter-form-field">
    <mat-label>Suche</mat-label>
    <input matInput [formControl]="userSearch" #input />
    <span class="button-clear" mat-icon-button matSuffix (click)="userSearch.patchValue('')">
      <mat-icon>close</mat-icon>
    </span>
  </mat-form-field>
  <table mat-table [dataSource]="dataSource" matSort matSortActive="merchantNumber" matSortDirection="asc">
    <ng-container matColumnDef="merchantNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Händlernummer</th>
      <td mat-cell *matCellDef="let row">{{ row.merchantNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let row">{{ row.email }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Aktionen</th>
      <td mat-cell *matCellDef="let row">
        <div class="actions">
          <a (click)="editUser(row)">
            <img src="./assets/icons/edit-red.png" alt="" height="20px" />
          </a>
          <a *ngIf="notAdmin(row)" (click)="confirmPasswordReset(row)">
            <ion-icon name="key" class="action-icon"></ion-icon>
          </a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Keine Benutzer gefunden"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
</div>
