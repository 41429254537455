import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IInvoiceEdit } from "src/app/models/edit";
import { ApiInvoiceService } from "src/app/services/api/api-invoice.service";
import { LoaderService } from "src/app/services/loader.service";
import { MessageService } from "src/app/services/message.service";
import { UiShareService } from "src/app/services/ui-share.service";

@Component({
  selector: "app-admin-invoice-dialog",
  templateUrl: "./admin-invoice-dialog.component.html",
  styleUrls: ["./admin-invoice-dialog.component.scss"],
})
export class AdminInvoiceDialogComponent implements OnInit {
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AdminInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private invoiceService: ApiInvoiceService,
    private uiShareService: UiShareService,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      paid: [true],
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      // anti-flicker
      if (!result) {
        this.loaderService.hideLoader();
      }
    });
  }

  setNewStatus() {
    const formValue = this.form.value;
    const edit: IInvoiceEdit = {
      invoiceNumber: this.data.invoiceNumber,
      paid: formValue.paid,
    };

    this.loaderService.showLoaderDelayed().subscribe(() => {
      this.invoiceService.editInvoice(edit).subscribe({
        next: () => {
          this.uiShareService.invoicesNeedReload = true;
          this.messageService.success("Successfully changed");
          this.dialogRef.close(true);
        },
        error: () => {
          this.messageService.error("Failed to change!");
          this.dialogRef.close();
        },
      });
    });
  }
}
